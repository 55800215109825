import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ApiAuctionsListingParams,
  ApiGetAuctionBidsParams,
  ApiGetAuctionsParams,
  ApiGetStateTransitionsParams,
  ApiReconcileAuction201,
  AuctionBrandsDto,
  AuctionEntity,
  AuctionItemEntity,
  AuctionModelDto,
  AuctionVersionDto,
  AuctionsListingDto,
  BackendErrorEntity,
  CreateAuctionBodyDto,
  GetAuctionBidsResponseDto,
  GetAuctionDealBidsResponseDto,
  GetAuctionRelistsResponseDto,
  GetAuctionsResponseDto,
  GetStateTransitionsResponseDto,
  ReconcileAuctionBodyDto,
  ScheduleAuctionBodyDto,
  SetClearingPriceBodyDto,
  SetStateBodyDto,
  UpdateAuctionBodyDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiAuctionsListingVersions = (
    modelId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      modelId = unref(modelId);
      
      return fetchApiInstance<AuctionVersionDto>(
      {url: `/auctions/model/${modelId}`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionsListingVersionsQueryKey = (modelId: MaybeRef<string | undefined | null>,) => {
    return ['auctions','model',modelId] as const;
    }

    
export const getApiAuctionsListingVersionsQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError = ErrorType<BackendErrorEntity>>(modelId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingVersionsQueryKey(modelId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListingVersions>>> = () => apiAuctionsListingVersions(modelId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(modelId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError, TData> 
}

export type ApiAuctionsListingVersionsQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListingVersions>>>
export type ApiAuctionsListingVersionsQueryError = ErrorType<BackendErrorEntity>



export function useApiAuctionsListingVersions<TData = Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError = ErrorType<BackendErrorEntity>>(
 modelId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingVersions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAuctionsListingVersionsQueryOptions(modelId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionsListingModels = (
    brandId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      brandId = unref(brandId);
      
      return fetchApiInstance<AuctionModelDto>(
      {url: `/auctions/brand/${brandId}`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionsListingModelsQueryKey = (brandId: MaybeRef<string | undefined | null>,) => {
    return ['auctions','brand',brandId] as const;
    }

    
export const getApiAuctionsListingModelsQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError = ErrorType<BackendErrorEntity>>(brandId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingModelsQueryKey(brandId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListingModels>>> = () => apiAuctionsListingModels(brandId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(brandId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError, TData> 
}

export type ApiAuctionsListingModelsQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListingModels>>>
export type ApiAuctionsListingModelsQueryError = ErrorType<BackendErrorEntity>



export function useApiAuctionsListingModels<TData = Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError = ErrorType<BackendErrorEntity>>(
 brandId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingModels>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAuctionsListingModelsQueryOptions(brandId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionsListingBrands = (
    
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      
      
      return fetchApiInstance<AuctionBrandsDto>(
      {url: `/auctions/brands`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionsListingBrandsQueryKey = () => {
    return ['auctions','brands'] as const;
    }

    
export const getApiAuctionsListingBrandsQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError = ErrorType<BackendErrorEntity>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingBrandsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListingBrands>>> = () => apiAuctionsListingBrands(requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError, TData> 
}

export type ApiAuctionsListingBrandsQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListingBrands>>>
export type ApiAuctionsListingBrandsQueryError = ErrorType<BackendErrorEntity>



export function useApiAuctionsListingBrands<TData = Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError = ErrorType<BackendErrorEntity>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListingBrands>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAuctionsListingBrandsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionsListing = (
    params?: MaybeRef<ApiAuctionsListingParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<AuctionsListingDto>(
      {url: `/auctions/listing`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiAuctionsListingQueryKey = (params?: MaybeRef<ApiAuctionsListingParams>,) => {
    return ['auctions','listing', ...(params ? [params]: [])] as const;
    }

    
export const getApiAuctionsListingQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionsListing>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiAuctionsListingParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListing>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionsListingQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionsListing>>> = () => apiAuctionsListing(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListing>>, TError, TData> 
}

export type ApiAuctionsListingQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionsListing>>>
export type ApiAuctionsListingQueryError = ErrorType<BackendErrorEntity>



export function useApiAuctionsListing<TData = Awaited<ReturnType<typeof apiAuctionsListing>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiAuctionsListingParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionsListing>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAuctionsListingQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiAuctionDetail = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<AuctionItemEntity>(
      {url: `/auctions/${auctionId}/detail`, method: 'GET'
    },
      options);
    }
  

export const getApiAuctionDetailQueryKey = (auctionId: MaybeRef<string | undefined | null>,) => {
    return ['auctions',auctionId,'detail'] as const;
    }

    
export const getApiAuctionDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiAuctionDetail>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionDetail>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiAuctionDetailQueryKey(auctionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiAuctionDetail>>> = () => apiAuctionDetail(auctionId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiAuctionDetail>>, TError, TData> 
}

export type ApiAuctionDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiAuctionDetail>>>
export type ApiAuctionDetailQueryError = ErrorType<BackendErrorEntity>



export function useApiAuctionDetail<TData = Awaited<ReturnType<typeof apiAuctionDetail>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiAuctionDetail>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiAuctionDetailQueryOptions(auctionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiApproveAuction = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<AuctionItemEntity>(
      {url: `/auctions/${auctionId}/approve`, method: 'PATCH'
    },
      options);
    }
  


export const getApiApproveAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiApproveAuction>>, TError,{auctionId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiApproveAuction>>, TError,{auctionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiApproveAuction>>, {auctionId: string}> = (props) => {
          const {auctionId} = props ?? {};

          return  apiApproveAuction(auctionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiApproveAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiApproveAuction>>>
    
    export type ApiApproveAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiApproveAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiApproveAuction>>, TError,{auctionId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiApproveAuction>>,
        TError,
        {auctionId: string},
        TContext
      > => {

      const mutationOptions = getApiApproveAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetSellerInvoicePdf = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<void>(
      {url: `/auctions/${auctionId}/getSellerInvoicePdf`, method: 'GET'
    },
      options);
    }
  

export const getApiGetSellerInvoicePdfQueryKey = (auctionId: MaybeRef<string | undefined | null>,) => {
    return ['auctions',auctionId,'getSellerInvoicePdf'] as const;
    }

    
export const getApiGetSellerInvoicePdfQueryOptions = <TData = Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetSellerInvoicePdfQueryKey(auctionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>> = () => apiGetSellerInvoicePdf(auctionId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>, TError, TData> 
}

export type ApiGetSellerInvoicePdfQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>>
export type ApiGetSellerInvoicePdfQueryError = ErrorType<BackendErrorEntity>



export function useApiGetSellerInvoicePdf<TData = Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetSellerInvoicePdf>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetSellerInvoicePdfQueryOptions(auctionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetAuctions = (
    params?: MaybeRef<ApiGetAuctionsParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<GetAuctionsResponseDto>(
      {url: `/auctions`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetAuctionsQueryKey = (params?: MaybeRef<ApiGetAuctionsParams>,) => {
    return ['auctions', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetAuctionsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetAuctions>>, TError = ErrorType<BackendErrorEntity>>(params?: MaybeRef<ApiGetAuctionsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetAuctionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetAuctions>>> = () => apiGetAuctions(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctions>>, TError, TData> 
}

export type ApiGetAuctionsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetAuctions>>>
export type ApiGetAuctionsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetAuctions<TData = Awaited<ReturnType<typeof apiGetAuctions>>, TError = ErrorType<BackendErrorEntity>>(
 params?: MaybeRef<ApiGetAuctionsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetAuctionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiCreateAuction = (
    createAuctionBodyDto: MaybeRef<CreateAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      createAuctionBodyDto = unref(createAuctionBodyDto);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createAuctionBodyDto
    },
      options);
    }
  


export const getApiCreateAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateAuction>>, TError,{data: BodyType<CreateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiCreateAuction>>, TError,{data: BodyType<CreateAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiCreateAuction>>, {data: BodyType<CreateAuctionBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiCreateAuction(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiCreateAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiCreateAuction>>>
    export type ApiCreateAuctionMutationBody = BodyType<CreateAuctionBodyDto>
    export type ApiCreateAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiCreateAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiCreateAuction>>, TError,{data: BodyType<CreateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiCreateAuction>>,
        TError,
        {data: BodyType<CreateAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiCreateAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetStateTransitions = (
    params: MaybeRef<ApiGetStateTransitionsParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<GetStateTransitionsResponseDto>(
      {url: `/auctions/getStateTransitions`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetStateTransitionsQueryKey = (params: MaybeRef<ApiGetStateTransitionsParams>,) => {
    return ['auctions','getStateTransitions', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetStateTransitionsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetStateTransitions>>, TError = ErrorType<BackendErrorEntity>>(params: MaybeRef<ApiGetStateTransitionsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetStateTransitions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetStateTransitionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetStateTransitions>>> = () => apiGetStateTransitions(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetStateTransitions>>, TError, TData> 
}

export type ApiGetStateTransitionsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetStateTransitions>>>
export type ApiGetStateTransitionsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetStateTransitions<TData = Awaited<ReturnType<typeof apiGetStateTransitions>>, TError = ErrorType<BackendErrorEntity>>(
 params: MaybeRef<ApiGetStateTransitionsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetStateTransitions>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetStateTransitionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetAuction = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions/${auctionId}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetAuctionQueryKey = (auctionId: MaybeRef<string | undefined | null>,) => {
    return ['auctions',auctionId] as const;
    }

    
export const getApiGetAuctionQueryOptions = <TData = Awaited<ReturnType<typeof apiGetAuction>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuction>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetAuctionQueryKey(auctionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetAuction>>> = () => apiGetAuction(auctionId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetAuction>>, TError, TData> 
}

export type ApiGetAuctionQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetAuction>>>
export type ApiGetAuctionQueryError = ErrorType<BackendErrorEntity>



export function useApiGetAuction<TData = Awaited<ReturnType<typeof apiGetAuction>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuction>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetAuctionQueryOptions(auctionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiUpdateAuction = (
    auctionId: MaybeRef<string | undefined | null>,
    updateAuctionBodyDto: MaybeRef<UpdateAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
updateAuctionBodyDto = unref(updateAuctionBodyDto);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions/${auctionId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateAuctionBodyDto
    },
      options);
    }
  


export const getApiUpdateAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAuction>>, TError,{auctionId: string;data: BodyType<UpdateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAuction>>, TError,{auctionId: string;data: BodyType<UpdateAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiUpdateAuction>>, {auctionId: string;data: BodyType<UpdateAuctionBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiUpdateAuction(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiUpdateAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiUpdateAuction>>>
    export type ApiUpdateAuctionMutationBody = BodyType<UpdateAuctionBodyDto>
    export type ApiUpdateAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiUpdateAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiUpdateAuction>>, TError,{auctionId: string;data: BodyType<UpdateAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiUpdateAuction>>,
        TError,
        {auctionId: string;data: BodyType<UpdateAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiUpdateAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiScheduleAuction = (
    auctionId: MaybeRef<string | undefined | null>,
    scheduleAuctionBodyDto: MaybeRef<ScheduleAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
scheduleAuctionBodyDto = unref(scheduleAuctionBodyDto);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions/${auctionId}/schedule`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: scheduleAuctionBodyDto
    },
      options);
    }
  


export const getApiScheduleAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiScheduleAuction>>, TError,{auctionId: string;data: BodyType<ScheduleAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiScheduleAuction>>, TError,{auctionId: string;data: BodyType<ScheduleAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiScheduleAuction>>, {auctionId: string;data: BodyType<ScheduleAuctionBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiScheduleAuction(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiScheduleAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiScheduleAuction>>>
    export type ApiScheduleAuctionMutationBody = BodyType<ScheduleAuctionBodyDto>
    export type ApiScheduleAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiScheduleAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiScheduleAuction>>, TError,{auctionId: string;data: BodyType<ScheduleAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiScheduleAuction>>,
        TError,
        {auctionId: string;data: BodyType<ScheduleAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiScheduleAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiRelistAuction = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions/${auctionId}/relist`, method: 'POST'
    },
      options);
    }
  


export const getApiRelistAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiRelistAuction>>, TError,{auctionId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiRelistAuction>>, TError,{auctionId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiRelistAuction>>, {auctionId: string}> = (props) => {
          const {auctionId} = props ?? {};

          return  apiRelistAuction(auctionId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiRelistAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiRelistAuction>>>
    
    export type ApiRelistAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiRelistAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiRelistAuction>>, TError,{auctionId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiRelistAuction>>,
        TError,
        {auctionId: string},
        TContext
      > => {

      const mutationOptions = getApiRelistAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetAuctionRelists = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<GetAuctionRelistsResponseDto>(
      {url: `/auctions/${auctionId}/relists`, method: 'GET'
    },
      options);
    }
  

export const getApiGetAuctionRelistsQueryKey = (auctionId: MaybeRef<string | undefined | null>,) => {
    return ['auctions',auctionId,'relists'] as const;
    }

    
export const getApiGetAuctionRelistsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetAuctionRelists>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionRelists>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetAuctionRelistsQueryKey(auctionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetAuctionRelists>>> = () => apiGetAuctionRelists(auctionId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionRelists>>, TError, TData> 
}

export type ApiGetAuctionRelistsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetAuctionRelists>>>
export type ApiGetAuctionRelistsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetAuctionRelists<TData = Awaited<ReturnType<typeof apiGetAuctionRelists>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionRelists>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetAuctionRelistsQueryOptions(auctionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiSetClearingPrice = (
    auctionId: MaybeRef<string | undefined | null>,
    setClearingPriceBodyDto: MaybeRef<SetClearingPriceBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
setClearingPriceBodyDto = unref(setClearingPriceBodyDto);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions/${auctionId}/clearingPrice`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: setClearingPriceBodyDto
    },
      options);
    }
  


export const getApiSetClearingPriceMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSetClearingPrice>>, TError,{auctionId: string;data: BodyType<SetClearingPriceBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiSetClearingPrice>>, TError,{auctionId: string;data: BodyType<SetClearingPriceBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiSetClearingPrice>>, {auctionId: string;data: BodyType<SetClearingPriceBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiSetClearingPrice(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiSetClearingPriceMutationResult = NonNullable<Awaited<ReturnType<typeof apiSetClearingPrice>>>
    export type ApiSetClearingPriceMutationBody = BodyType<SetClearingPriceBodyDto>
    export type ApiSetClearingPriceMutationError = ErrorType<BackendErrorEntity>

    export const useApiSetClearingPrice = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSetClearingPrice>>, TError,{auctionId: string;data: BodyType<SetClearingPriceBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiSetClearingPrice>>,
        TError,
        {auctionId: string;data: BodyType<SetClearingPriceBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiSetClearingPriceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiReconcileAuction = (
    auctionId: MaybeRef<string | undefined | null>,
    reconcileAuctionBodyDto: MaybeRef<ReconcileAuctionBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
reconcileAuctionBodyDto = unref(reconcileAuctionBodyDto);
      
      return fetchApiInstance<ApiReconcileAuction201>(
      {url: `/auctions/${auctionId}/reconcile`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: reconcileAuctionBodyDto
    },
      options);
    }
  


export const getApiReconcileAuctionMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiReconcileAuction>>, TError,{auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiReconcileAuction>>, TError,{auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiReconcileAuction>>, {auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiReconcileAuction(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiReconcileAuctionMutationResult = NonNullable<Awaited<ReturnType<typeof apiReconcileAuction>>>
    export type ApiReconcileAuctionMutationBody = BodyType<ReconcileAuctionBodyDto>
    export type ApiReconcileAuctionMutationError = ErrorType<BackendErrorEntity>

    export const useApiReconcileAuction = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiReconcileAuction>>, TError,{auctionId: string;data: BodyType<ReconcileAuctionBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiReconcileAuction>>,
        TError,
        {auctionId: string;data: BodyType<ReconcileAuctionBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiReconcileAuctionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiSetState = (
    auctionId: MaybeRef<string | undefined | null>,
    setStateBodyDto: MaybeRef<SetStateBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
setStateBodyDto = unref(setStateBodyDto);
      
      return fetchApiInstance<AuctionEntity>(
      {url: `/auctions/${auctionId}/setState`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: setStateBodyDto
    },
      options);
    }
  


export const getApiSetStateMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSetState>>, TError,{auctionId: string;data: BodyType<SetStateBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiSetState>>, TError,{auctionId: string;data: BodyType<SetStateBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiSetState>>, {auctionId: string;data: BodyType<SetStateBodyDto>}> = (props) => {
          const {auctionId,data} = props ?? {};

          return  apiSetState(auctionId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiSetStateMutationResult = NonNullable<Awaited<ReturnType<typeof apiSetState>>>
    export type ApiSetStateMutationBody = BodyType<SetStateBodyDto>
    export type ApiSetStateMutationError = ErrorType<BackendErrorEntity>

    export const useApiSetState = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSetState>>, TError,{auctionId: string;data: BodyType<SetStateBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiSetState>>,
        TError,
        {auctionId: string;data: BodyType<SetStateBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiSetStateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetAuctionBids = (
    auctionId: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiGetAuctionBidsParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
params = unref(params);
      
      return fetchApiInstance<GetAuctionBidsResponseDto>(
      {url: `/auctions/${auctionId}/bids`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetAuctionBidsQueryKey = (auctionId: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiGetAuctionBidsParams>,) => {
    return ['auctions',auctionId,'bids', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetAuctionBidsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetAuctionBids>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiGetAuctionBidsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionBids>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetAuctionBidsQueryKey(auctionId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetAuctionBids>>> = () => apiGetAuctionBids(auctionId,params, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionBids>>, TError, TData> 
}

export type ApiGetAuctionBidsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetAuctionBids>>>
export type ApiGetAuctionBidsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetAuctionBids<TData = Awaited<ReturnType<typeof apiGetAuctionBids>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>,
    params?: MaybeRef<ApiGetAuctionBidsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionBids>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetAuctionBidsQueryOptions(auctionId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiGetAuctionDealBids = (
    auctionId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      auctionId = unref(auctionId);
      
      return fetchApiInstance<GetAuctionDealBidsResponseDto>(
      {url: `/auctions/${auctionId}/dealBids`, method: 'GET'
    },
      options);
    }
  

export const getApiGetAuctionDealBidsQueryKey = (auctionId: MaybeRef<string | undefined | null>,) => {
    return ['auctions',auctionId,'dealBids'] as const;
    }

    
export const getApiGetAuctionDealBidsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetAuctionDealBids>>, TError = ErrorType<BackendErrorEntity>>(auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionDealBids>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetAuctionDealBidsQueryKey(auctionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetAuctionDealBids>>> = () => apiGetAuctionDealBids(auctionId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(auctionId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionDealBids>>, TError, TData> 
}

export type ApiGetAuctionDealBidsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetAuctionDealBids>>>
export type ApiGetAuctionDealBidsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetAuctionDealBids<TData = Awaited<ReturnType<typeof apiGetAuctionDealBids>>, TError = ErrorType<BackendErrorEntity>>(
 auctionId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetAuctionDealBids>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetAuctionDealBidsQueryOptions(auctionId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



